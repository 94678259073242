import { useEffect, useState } from "react";
import { getAuth } from "firebase/auth";
import { db } from "../../utils/firebase";
import { query, where,onSnapshot, collection,doc, addDoc,getDoc, updateDoc, getDocs} from "firebase/firestore";
//import firebase from "/firebase";


const data = [
  {
    id: 1,
    //src: "https://d1pnnwteuly8z3.cloudfront.net/images/9faa0c18-fdc0-4286-bb20-a4131d9ef9b6/a29b99ed-1b0d-4a18-9ae7-8c64094a96cf.png",
    title: "Basic",
    price: "99",
  },
];
const Bills80 = () => {
  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");
  const [planType, setPlanType] =  useState("Basic");;
  

useEffect(() => {
  
  const auth = getAuth();
  auth.onAuthStateChanged((user) => {
    if (user) {
      setUserId(user.uid);
      setUserName(user.displayName);
      console.log("checkout(Number(1))")
      if (data.length > 0) {
        checkout(Number(data[0].price));
      }
      const userRef = query(collection(db, "users"), where("uid", "==", user.uid));
      const unsubscribe = onSnapshot(userRef, (snapshot) => {
        if (!snapshot.empty) {
          snapshot.forEach((doc) => {
            const userData = doc.data();
            if (userData && userData.subscription) {
              setPlanType(userData.subscription.planType || "Basic");
              
              
            }
          });
        }
      });
      
      return () => unsubscribe();
    } else {
      window.location.href = "#/login";
    }
  });
}, [userId]);

  const DBaddition = async() => {
    const auth = getAuth();
    const user = auth.currentUser;

    // Ensure the user is signed in.
    if (!user) {
        console.error("User is not authenticated.");
        window.location.href = "#/login";
        return;
    }
    // const querySnapshot = await getDocs(collection(db, "users"));
    // querySnapshot.forEach((doc) => {

    //   // doc.data() is never undefined for query doc snapshots
    //   console.log(doc.id, " => ", doc.data());
    // });
    console.log(user.data.email)

    const q = query(collection(db, "users"), where("email", "==", user.data.email));

    const querySnapshot = await getDocs(q);
    var docid="";
    querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    console.log(doc.id, " => ", doc.data());
    docid=doc.id
    });
    // Fetch the user's document from Firestore.
    console.log(docid)
    const docRef = doc(db, "users", docid);


    //const userData = userDoc.data();
    const currentDate = new Date(user.data.exp);

    // Add 3 months to the current date.
    currentDate.setMonth(currentDate.getMonth() + 2);
    const dateString = currentDate.toDateString();
      console.log("Successful payment");
      localStorage.setItem('proversion', 'true');
      localStorage.setItem('exp', dateString);
      // Update Firestore document.
      try {
          await updateDoc(docRef, {
              pro: true,
              exp: dateString
          });
          console.log("Document successfully updated!");
          localStorage.setItem('exp', dateString);
      } catch (error) {
          console.error("Error updating document: ", error);
      }
  }
  

  const checkout = (plan) => {
    fetch("https://cf-backend-c9if.onrender.com/api/v1/create-subscription-checkout-session", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify({ plan: plan, customerId: userId }),
    })
      .then((res) => {
        if (res.ok) return res.json();
        console.log(res);
        localStorage.setItem('proversion', 'false');
        return res.json().then((json) => Promise.reject(json));
      })
      .then(({ session }) => {
        localStorage.setItem('proversion', 'true');
        DBaddition();
        window.location = session.url;


        

      })
      .catch((e) => {
        localStorage.setItem('proversion', 'false');
        console.log(e.error);
      });
  };
  return (
    <>
    <div className="flex flex-col items-center w-full mx-auto min-h-screen diagonal-background overflow-x-hidden">
      <h1>payment processing...</h1>
    </div>
  </>
  );
};
export default Bills80;
